






























































import {
  Component, Prop, Provide, Watch,
} from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { mixins } from 'vue-class-component';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import ClientStorage from '@/utils/ClientStore';
import WizardStepsEnum from '@/utils/enums/WizardStepsEnum';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';

@Component({
  components: {
    FontAwesomeComponent,
  },
})
export default class WizardWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'WizardWidgetStore';

  @Provide('wizardStoreName')
  protected wizardStoreName = { value: '' };

  @Prop({ required: false, default: '' })
  private title!: string;

  @Prop({ required: false, default: '' })
  private steps!: { title: string }[]

  @Prop({ required: false, default: false })
  private clickableStep!: boolean;

  @Prop({ required: false, default: false })
  private navigateBackward!: boolean;

  @Prop({ required: false, default: false })
  private navigateForward!: boolean;

  @Prop({ required: false, default: 'step' })
  private stepParamKey!: string;

  @Prop({ required: false, default: true })
  private displaySteps!: boolean;

  private get currentPos(): number {
    return this.$store.getters[`${this.widgetStorePath}/currentPos`];
  }

  created(): void {
    this.setDataConfig();
    this.wizardStoreName.value = this.widgetStorePath;
  }

  // eslint-disable-next-line class-methods-use-this
  mounted(): void {
    ClientStorage.setItem(WizardStepsEnum.VISIT_WIZARD, String(DateTimeHelper.currentTimestamp));
  }

  @Watch('isReadyToDisplay')
  navigate(): void {
    const params = new URLSearchParams(window.location.search);
    let stepPosition: number | string = params.get(this.stepParamKey) || '1';
    // eslint-disable-next-line no-restricted-globals
    const isStringNumber = !isNaN(Math.abs(stepPosition as unknown as number));
    stepPosition = isStringNumber ? parseInt(stepPosition, 10) : stepPosition;
    this.setStep(stepPosition);
  }

  private goTo(position: number): void {
    if (this.clickableStep) {
      this.setStep(position);
    }
  }

  private setStep(position: number | string): void {
    this.$store.dispatch(`${this.widgetStorePath}/gotoStep`, position);
  }

  private prevStep(): void {
    this.$store.dispatch(`${this.widgetStorePath}/prevStep`);
  }

  private nextStep(): void {
    this.$store.dispatch(`${this.widgetStorePath}/nextStep`);
  }
}
